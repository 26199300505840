<app-preorder-top-banner
  (scrollToProducts)="scrollToClickedCategory()"
  *ngIf="isNewPreOrderEnabled"
></app-preorder-top-banner>
<div id="category-products" class="categories-page-container">
  <app-last-category-sales-report-card
    *ngIf="isDiscoverabilityFunnelEnabled && !isPreOrderUIRevampEnabled"
    [shouldDisplayBackground]="true"
  ></app-last-category-sales-report-card>
  <app-preorder-requests-summary *ngIf="isNewPreOrderEnabled"></app-preorder-requests-summary>
  <div class="row">
    <app-breadcrumbs
      *ngIf="state === 'loaded'"
      [categoryHierachy]="categoryHierachy"
      [isNewPreOrderEnabled]="isNewPreOrderEnabled"
      (emittedCategoryId)="getSelectedCommercialCategories($event)"
    ></app-breadcrumbs>
  </div>
  <p class="page-title">
    {{
      (isEnglishLanguageSelected
        ? selectedCategoryName?.englishName
        : selectedCategoryName?.arabicName
      ) | translate
    }}
  </p>
  <div class="grid mt-3">
    <div class="row wrapper">
      <div
        class="col-12 col-lg-3 col-md-3 item d-none d-lg-block"
        *ngIf="!isDiscoverabilityFunnelEnabled || isPreOrderUIRevampEnabled"
      >
        <app-level-one-categories-sidebar
          *ngIf="isCategoriesLoaded && categoryHierarchyHasLessThanTwoLevels"
          [categoriesNodes]="levelOneCategoriesNodes"
          [selectedCategory]="selectedCategoryName"
          [queryParamsObject]="queryParamsObject"
          [isPreOrderable]="isPreOrderableProducts && isPreOrderUIRevampEnabled"
          (emittedCategoryId)="getSelectedCommercialCategories($event)"
          [isNewPreOrderEnabled]="isNewPreOrderEnabled"
        ></app-level-one-categories-sidebar>

        <app-categories-sidebar
          #categoriesSidebar
          *ngIf="isCategoriesLoaded && !categoryHierarchyHasLessThanTwoLevels"
          [selectedCategoryName]="pageTitle"
          [selectedCategoryHierarchy]="categoryHierachy"
          [isPreOrderable]="isPreOrderableProducts && isPreOrderUIRevampEnabled"
          (emittedCategoryId)="getSelectedCommercialCategories($event)"
          [isNewPreOrderEnabled]="isNewPreOrderEnabled"
        ></app-categories-sidebar>
        <ng-container *ngIf="!isPreOrderUIRevampEnabled">
          <div>
            <div class="mini-products-header" *ngIf="miniProductsOne.length">
              {{ 'CATEGORY_PAGE.WINNING_PRODUCTS' | translate }}
            </div>
            <app-mini-product-card
              *ngFor="let miniProduct of miniProductsOne"
              [product]="miniProduct"
            ></app-mini-product-card>
          </div>
          <div>
            <div class="mini-products-header" *ngIf="miniProductsTwo.length">
              {{ 'CATEGORY_PAGE.NEWLY_ARRIVED' | translate }}
            </div>
            <app-mini-product-card
              *ngFor="let miniProduct of miniProductsTwo"
              [product]="miniProduct"
            ></app-mini-product-card>
          </div>
        </ng-container>
      </div>

      <div class="col-12 d-lg-none collapse-wrap" *ngIf="isCategoriesLoaded">
        <button
          type="button"
          class="btn btn-primary collapse-category"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseBasic"
          [ngClass]="{ opened: !isCollapsed }"
        >
          {{ isEnglishLanguageSelected ? pageTitle.englishName : pageTitle.arabicName }}
          <img loading="lazy" class="collapse-icon" src="../../../assets/img/dropdown.svg" />
        </button>
        <div
          id="collapseBasic"
          [collapse]="isCollapsed"
          [ngClass]="{ 'd-flex': !isCollapsed }"
          class="card category-list align-items-center"
        >
          <div appHoverStyle *ngFor="let category of levelOneCategoriesNodes">
            <a
              class="category-el d-flex"
              (click)="isCollapsed = true"
              [routerLink]="['/', 'products', 'category', category.key]"
              [queryParams]="{
                currentPage: 1,
                items: queryParamsObject.items,
                sorting: queryParamsObject.sorting
              }"
              [ngClass]="
                category.value.name.arabicName === selectedCategoryName.arabicName ? 'selected' : ''
              "
            >
              <img
                loading="lazy"
                class="category-icon"
                [src]="categoryIconUrl(category.value.icon)"
              />
              <p class="category-name">
                {{
                  isEnglishLanguageSelected
                    ? category.value.name.englishName
                    : category.value.name.arabicName
                }}
              </p>
            </a>
          </div>
        </div>
      </div>

      <div
        [ngClass]="
          isDiscoverabilityFunnelEnabled && !isPreOrderUIRevampEnabled
            ? 'discoverability-wrapper'
            : 'col-12 col-lg-9 col-md-12'
        "
      >
        <app-subcategory-filter
          *ngIf="categoryHierachyHasFourlevels"
          [fourthLevelCategoriesList]="fourthLevelCategoriesList"
        ></app-subcategory-filter>
        <div
          class="d-flex flex-wrap justify-content-between"
          [ngClass]="
            isDiscoverabilityFunnelEnabled && !isPreOrderUIRevampEnabled
              ? 'filters-wrapper'
              : 'd-flex flex-wrap justify-content-between'
          "
          *ngIf="variantGroups.length || products.length"
        >
          <div class="category-heading">
            <span>({{ noOfItems }} {{ 'CATEGORY_PAGE.RESULTS' | translate }})</span>
          </div>
          <div class="d-flex justify-content-between flex-wrap" *ngIf="!featureGroupTitle">
            <div class="btn-group" dropdown>
              <span class="items-text">{{ 'CATEGORY_PAGE.SHOW' | translate }}</span>
              <button
                id="button-animated"
                dropdownToggle
                type="button"
                class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-animated"
              >
                <span class="selected-items-per-page">
                  {{ maxItemPerPage }} {{ 'CATEGORY_PAGE.PER_PAGE' | translate }}
                </span>
                <i class="icon icon-dropdown" aria-hidden="true"></i>
              </button>
              <ul
                id="dropdown-animated"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="button-animated"
              >
                <li
                  *ngFor="let pageFilterSize of pagesFilterSize"
                  role="menuitem"
                  class="dropdown-item"
                  (click)="changeItemsOnPage(pageFilterSize)"
                >
                  {{ pageFilterSize }}
                </li>
              </ul>
            </div>
            <div class="btn-group" dropdown>
              <span class="items-text">{{ 'CATEGORY_PAGE.SORT_BY' | translate }}</span>
              <button
                id="button-animated"
                dropdownToggle
                type="button"
                class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-animated"
              >
                <span class="selected-sorting">{{ sortedBy?.translationKey | translate }}</span>
                <i class="icon icon-dropdown" aria-hidden="true"></i>
              </button>
              <ul
                id="dropdown-animated"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="button-animated"
              >
                <li
                  role="menuitem"
                  class="dropdown-item"
                  *ngFor="let sortingOption of sortingOptions"
                  (click)="changeSorting(sortingOption.value)"
                >
                  {{ sortingOption.translationKey | translate }}
                </li>
              </ul>
            </div>
            <div class="sale-box" *ngIf="!isPreOrderableProducts">
              <mat-checkbox [checked]="onlyGroupsOnSale" (change)="changeOnSaleGroup($event)">
                <span class="check-select-all">{{ 'CATEGORY_PAGE.FLASH_SALES' | translate }}</span>
              </mat-checkbox>
            </div>
            <div
              class="discounted-products-box"
              *ngIf="!isPreOrderableProducts && shouldShowDiscountedAsSecondProductFlag"
            >
              <mat-checkbox
                [checked]="onlyDiscountedAsSecondInPlacement"
                (change)="changeOnDiscountedProducts($event)"
              >
                <span class="check-select-all">
                  {{ 'CATEGORY_PAGE.SECOND_PRODUCT_DISCOUNT' | translate }}
                </span>
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div
            *ngIf="
              state === 'loaded' &&
                products.length === 0 &&
                variantGroups.length === 0 &&
                testingProducts.length === 0;
              else showProducts
            "
            id="no-products"
          >
            <div class="col col-xs-12">
              <span>{{ 'CATEGORY_PAGE.NO_PRODUCTS_FOUND' | translate }}</span>
            </div>
          </div>
          <ng-template #showProducts>
            <div *ngIf="state === 'loading'" class="container loading-container d-flex flex-center">
              <loader [loading]="state === 'loading'" size="md" alt="loading" id="loading"></loader>
            </div>
            <div
              class="col-12"
              *ngIf="
                state === 'loaded' && shouldShowTestableProductsSearch && testingProducts.length > 0
              "
            >
              <p class="title-testing">Testable products</p>
              <div
                class="vertical-scroll"
                [ngClass]="{ 'width-align': testingProducts.length > 3 }"
              >
                <app-product-card
                  *ngFor="let product of testingProducts"
                  [product]="product"
                  [shouldShowStockDistribution]="
                    shouldShowStockDistribution && product.availabilityInfo?.stockInfo
                  "
                ></app-product-card>
              </div>
            </div>
            <div
              class="col-12 d-flex flex-wrap justify-content-flex-start"
              *ngIf="state === 'loaded' && isCatalogDataLoaded"
            >
              <app-product-card
                *ngFor="let variantGroup of variantGroups"
                [variantGroup]="variantGroup"
                [isFromLockedCarousel]="lockedOnly"
                [shouldShowStockDistribution]="
                  shouldShowStockDistribution &&
                  variantGroup.primaryVariant?.availabilityInfo?.stockInfo
                "
              ></app-product-card>
              <app-product-card
                *ngFor="let product of products"
                [product]="product"
                [shouldShowStockDistribution]="
                  shouldShowStockDistribution && product.availabilityInfo?.stockInfo
                "
              ></app-product-card>
            </div>
          </ng-template>
        </div>
        <div class="d-flex flex-column justify-content-center mb-5 mt-5 paginate">
          <div *ngIf="showPagination">
            <div class="pagination-wrapper d-flex justify-content-center">
              <pagination
                [totalItems]="noOfItems"
                [(ngModel)]="currentPage"
                [itemsPerPage]="maxItemPerPage"
                [maxSize]="maxPageSize"
                (pageChanged)="pageChanged($event)"
                previousText=" "
                nextText=" "
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-preorder-bottom-banner *ngIf="isNewPreOrderEnabled"></app-preorder-bottom-banner>
</div>
