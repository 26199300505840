/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-destructuring */

import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { country } from '@features/country/data';
import { LockedTestableProductFilterModel } from '@features/locked-testable-products/data/models';
import {
  LockedTestableProductData,
  LockedTestableProductsUseCaseResolver,
} from '@features/locked-testable-products/domain';
import { mediaUrl } from '@features/shared/data';
import { user } from '@features/user/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { combineLatest, Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { PreOrderStatuses } from 'src/app/core/domain/bulk-pre-order/pre-order-status.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetBulkPreOrderRequestsUseCase } from 'src/app/core/usecases/bulk-preorder/get-bulk-pre-order-requests.usecase';
import { GetPreOrderCommercialCategoriesUseCase } from 'src/app/core/usecases/bulk-preorder/get-pre-order-commercial-categories.usecase';
import { GetPreorderableProductsUseCase } from 'src/app/core/usecases/bulk-preorder/get-preorderable-products-use.case';
import { GetCategoryHierarchyUseCase } from 'src/app/core/usecases/get-category-hierarchy-usecase';
import { GetCommercialCategoriesUseCase } from 'src/app/core/usecases/get-commercial-categories-usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { ListenToProductsPageExperienceChangesUseCase } from 'src/app/core/usecases/products/listen-to-products-page-experience-change.usecase';
import { ShouldDisplayNewHomePageUseCase } from 'src/app/core/usecases/products/should-display-new-home-page.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import {
  ALL_PRODUCTS_CATEGORY,
  BULK_PREORDERABLE_CAROUSEL_NAME_ARABIC,
  BULK_PREORDERABLE_CAROUSEL_NAME_ENGLISH,
  COLLECTION_PRODUCTS_CAROUSEL_NAME,
  FEATURE_FLAGS,
  PAGES_FILTER_SIZE,
  PRE_ORDER_USER_FEATURE,
} from 'src/app/presentation/shared/constants';
import {
  CATEGORY_PRODUCTS_SORTING_OPTIONS,
  CATEGORY_PRODUCTS_USER_ACTIONS,
  DEFAULT_SORTING_OPTION,
  OLD_DEFAULT_SORTING_OPTION,
  SORT_BY_CLOSEST_MATCH_OPTION,
  SORT_BY_INTRODUCED_AT,
  SORT_BY_ORDER_COUNT,
} from 'src/app/presentation/shared/constants/category-products';
import { CategoryProductsUserActions } from 'src/app/presentation/shared/interfaces/category-products-actions';
import { CategoryInterface } from 'src/app/presentation/shared/interfaces/product.interafce';
import { VariantGroup } from 'src/app/presentation/shared/interfaces/variant';
import { CatalogService } from 'src/app/presentation/shared/services/catalog.service';
import { ProductService } from 'src/app/presentation/shared/services/product.service';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';
import { LastCategorySalesReportCardComponent } from '../../last-category/last-category-sales-report-card/last-category-sales-report-card.component';
import { BreadcrumbsComponent } from '../../shared/components/breadcrumbs/breadcrumbs.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { COMMERCIAL_CATEGORY_TREE_ROOT } from '../../shared/constants/commercial-categories';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import {
  FEATURED_PRODUCTS,
  GO_TO_CATEGORY,
  VIEW_PREORDERABLE_PRODUCTS_PAGE,
} from '../../shared/constants/mixpanel';
import { HoverStyleDirective } from '../../shared/directives/hover-style.directive';
import {
  CommercialCategoryTree,
  CommercialCategoryTreeNode,
} from '../../shared/interfaces/commercial-categories.interface';
import { featureAttributeAssign } from '../../shared/utilities/feature-attribute-assign.utility';
import { getSizedImage, ImageSize } from '../../shared/utilities/get-sized-image.utility';
import { ProductCardComponent } from '../products-v2/product-card/product-card.component';
import { CategoriesSidebarComponent } from './categories-sidebar/categories-sidebar.component';
import { LevelOneCategoriesSidebarComponent } from './level-one-categories-sidebar/level-one-categories-sidebar.component';
import { MiniProductCardComponent } from './mini-product-card/mini-product-card.component';
import { PreorderBottomBannerComponent } from './preorder-bottom-banner/preorder-bottom-banner.component';
import { PreorderRequestsSummaryComponent } from './preorder-requests-summary/preorder-requests-summary.component';
import { PreorderTopBannerComponent } from './preorder-top-banner/preorder-top-banner.component';
import { SubcategoryFilterComponent } from './subcategory-filter/subcategory-filter.component';

@Component({
  selector: 'app-category-products',
  templateUrl: './category-products.component.html',
  styleUrls: ['./category-products.component.scss'],
  standalone: true,
  imports: [
    BreadcrumbsComponent,
    NgIf,
    LevelOneCategoriesSidebarComponent,
    CategoriesSidebarComponent,
    NgFor,
    MiniProductCardComponent,
    NgClass,
    CollapseModule,
    HoverStyleDirective,
    RouterLink,
    SubcategoryFilterComponent,
    BsDropdownModule,
    MatCheckboxModule,
    LoaderComponent,
    ProductCardComponent,
    PaginationModule,
    FormsModule,
    TranslateModule,
    LastCategorySalesReportCardComponent,
    PreorderTopBannerComponent,
    PreorderBottomBannerComponent,
    PreorderRequestsSummaryComponent,
  ],
})
export class CategoryProductsComponent implements OnInit, OnDestroy {
  @ViewChild('categoriesSidebar') categoriesSidebar: CategoriesSidebarComponent;

  public variantGroups: VariantGroup[] | LockedTestableProductData[] = [];

  public products: any[] = [];

  public currentPage: number;

  public noOfItems: number;

  public maxItemPerPage: number;

  public sortedBy: { value: string; translationKey: string };

  public page: 1;

  public maxPageSize = 6;

  public levelOneCategoriesNodes: CommercialCategoryTreeNode[];

  public isCategoriesLoaded = false;

  public selectedCategoryName: { arabicName: string; englishName: string };

  public defaultCategory = ALL_PRODUCTS_CATEGORY;

  public featureGroupId: number;

  public featureGroupTitle: string;

  public defaultKey: string;

  public defaultCurrentPage = 1;

  public searchKey: string;

  public state = 'idle';

  public pageTitle: { arabicName: string; englishName: string };

  public showPagination: boolean;

  public isCollapsed = true;

  public isProductsAvailable: boolean;

  public miniProductsOne = [];

  public miniProductsTwo = [];

  public isCatalogDataLoaded = false;

  public currentUserAction: CategoryProductsUserActions;

  public userActions: { [key: string]: CategoryProductsUserActions } =
    CATEGORY_PRODUCTS_USER_ACTIONS;

  public sortingOptions = CATEGORY_PRODUCTS_SORTING_OPTIONS;

  public queryParamsObject: any;

  public selectedCategoryId = '';

  public categories: CategoryInterface[];

  public pagesFilterSize = PAGES_FILTER_SIZE;

  public isMobile: boolean;

  public fourthLevelCategoriesList: any;

  public categoryHierachy: CommercialCategoryTreeNode[];

  public categoryHierachyHasFourlevels = false;

  public categoryHierarchyHasLessThanTwoLevels = false;

  public introduceProductFeatureFlag = false;

  public searchOptimizationFeatureFlag = false;

  private subscriptions: Subscription[] = [];

  lockedOnly = false;

  public onlyGroupsOnSale = false;

  public shouldShowDiscountedAsSecondProductFlag = false;

  public onlyDiscountedAsSecondInPlacement = false;

  public isResizeImageEnabled = false;

  public isEnglishLanguageSelected = false;

  public shouldShowStockDistribution = false;

  public merchantLockedOnly = false;

  public isDiscoverabilityFunnelEnabled = false;

  public isPreOrderableProducts = false;

  public isPreOrderUIRevampEnabled = false;

  public isNewPreOrderEnabled = false;

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  public isLockedTestableProducts = false;

  public shouldShowTestableProductsSearch = false;

  public testingProducts: LockedTestableProductData[] = [];

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private catalogService: CatalogService,
    private getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private responsiveService: ResponsiveService,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _getPreorderableProducts: GetPreorderableProductsUseCase,
    private _getBulkPreOrderRequestsUseCase: GetBulkPreOrderRequestsUseCase,
    private _getFeatureAttributeUsecase: GetFeatureAttributeUsecase,
    private _translateService: TranslateService,
    private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase,
    private _getCategoryHierarchyUseCase: GetCategoryHierarchyUseCase,
    private _shouldDisplayNewHomePageUseCase: ShouldDisplayNewHomePageUseCase,
    private _listenToProductsPageExperienceChanges: ListenToProductsPageExperienceChangesUseCase,
    private _getPreOrderCommercialCategoriesUseCase: GetPreOrderCommercialCategoriesUseCase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
  ) {
    this.getMobileStatus();
  }

  ngOnInit(): void {
    this.checkPreOrderUIRevamp();
    this.route.queryParams.subscribe((params) => {
      this.isPreOrderableProducts = params.preorderablProducts;
      this.isLockedTestableProducts = params.lockedTestableProducts;
    });
    const currentCountry = country.code;
    const userHasPreOrder = this._checkUserFeatureExistsUseCase.execute(
      PRE_ORDER_USER_FEATURE + currentCountry.toLowerCase(),
    );
    this.isNewPreOrderEnabled =
      this.isPreOrderUIRevampEnabled && userHasPreOrder && this.isPreOrderableProducts;
    this._getIsEnglishLanguageSelected();
    this.isStockDistributionEnabled();
    this.isTestableProductSearchEnabled();
    this.checkUserEligibilityForDiscoverabilityFunnel();
    combineLatest({
      introduceProductFeatureFlag: this.getFeatureFlagUseCase.execute(
        FEATURE_FLAGS.INTRODUCE_PRODUCT_FEATURE,
      ),
      searchOptimizationFeatureFlag: this.getFeatureFlagUseCase.execute(
        FEATURE_FLAGS.SEARCH_OPTIMIZATION_FEATURE,
      ),
      shouldShowDiscountedAsSecondProductFlag: this.getFeatureFlagUseCase.execute(
        FEATURE_FLAGS.DISCOUNTED_AS_SECOND_PRODUCT,
      ),
    }).subscribe({
      next: ({
        introduceProductFeatureFlag,
        searchOptimizationFeatureFlag,
        shouldShowDiscountedAsSecondProductFlag,
      }) => {
        this.introduceProductFeatureFlag = introduceProductFeatureFlag;
        this.searchOptimizationFeatureFlag = searchOptimizationFeatureFlag;
        this.shouldShowDiscountedAsSecondProductFlag = shouldShowDiscountedAsSecondProductFlag;
        this.getSelectedCommercialCategories();
      },
    });
    this.getCatalogedProducts();
  }

  public categoryIconUrl(icon: string): string {
    return mediaUrl(`commercial-categories/icons/${icon}`);
  }

  scrollToClickedCategory(): void {
    document.getElementsByClassName('page-title')[0].scrollIntoView({ behavior: 'smooth' });
  }

  checkUserEligibilityForDiscoverabilityFunnel(): void {
    this.isDiscoverabilityFunnelEnabled = this._shouldDisplayNewHomePageUseCase.execute();
    this._listenToProductsPageExperienceChanges.execute().subscribe(() => {
      this.isDiscoverabilityFunnelEnabled = this._shouldDisplayNewHomePageUseCase.execute();
    });
  }

  public isStockDistributionEnabled(): void {
    this._getFeatureAttributeUsecase
      .execute(FEATURE_FLAGS.STOCK_DISTRIBUTION)
      .subscribe((attribute) => {
        this.shouldShowStockDistribution = featureAttributeAssign(attribute, Number(user.id));
      });
  }

  public isTestableProductSearchEnabled(): void {
    this._getFeatureAttributeUsecase.execute(FEATURE_FLAGS.WEB_TESTABLE_PRODUCTS_SEARCH).subscribe({
      next: (attribute) => {
        this.shouldShowTestableProductsSearch = featureAttributeAssign(
          attribute,
          Number(user.id),
          country.code,
        );
      },
    });
  }

  getMobileStatus(): void {
    this.responsiveService.getMobileStatus().subscribe({
      next: (res) => {
        this.isMobile = res;
      },
    });
  }

  getSelectedCommercialCategories(emittedCategoryId?: string): void {
    if (this.isNewPreOrderEnabled) {
      this._getPreOrderCommercialCategoriesUseCase
        .execute(country.code)
        .pipe(take(1))
        .subscribe({
          next: (tree) => {
            this.setUpCategories(tree, emittedCategoryId);
          },
        });
    } else {
      this.subscriptions.push(
        this.route.params.pipe().subscribe({
          next: (params) => {
            this._getCommercialCategoriesUseCase
              .execute(country.code)
              .pipe(take(1))
              .subscribe({
                next: (tree) => {
                  this.setUpCategories(tree, params);
                },
              });
          },
        }),
      );
    }
  }

  setUpCategories(tree: CommercialCategoryTree, params: any): void {
    if (tree) {
      if (params?.id) {
        this.selectedCategoryId = params.id;
        const data = {
          countryCode: country.code,
          categoryId: params.id,
        };

        this._getCategoryHierarchyUseCase.execute(data).subscribe((categoryHierarchy) => {
          if (categoryHierarchy.length) {
            this.categoryHierachy = categoryHierarchy;
            this.categoryHierachyHasFourlevels =
              categoryHierarchy.length > 3 &&
              categoryHierarchy[categoryHierarchy.length - 4].hasChildren;
            this.categoryHierarchyHasLessThanTwoLevels = false;
            if (categoryHierarchy.length < 3 && !categoryHierarchy[0].hasChildren) {
              this.categoryHierarchyHasLessThanTwoLevels = true;
              const firstLevelCategoryName = COMMERCIAL_CATEGORY_TREE_ROOT.name;
              this.pageTitle = firstLevelCategoryName;
              this.levelOneCategoriesNodes = tree.root.children;
              this.getQueryParams();
            }
            if (this.categoryHierachyHasFourlevels) {
              this.fourthLevelCategoriesList =
                categoryHierarchy[categoryHierarchy.length - 4].children;
              this.categoryHierarchyHasLessThanTwoLevels = false;
            }
            if (this.isMobile) {
              this.levelOneCategoriesNodes = tree.root.children;
            }
            this.selectedCategoryName = categoryHierarchy[0].value.name;
            this.categoriesSidebar?.getSelectedCategoryNodes(categoryHierarchy);
            this.isCategoriesLoaded = true;
            this.pageTitle = categoryHierarchy[categoryHierarchy.length - 2].value.name;

            this.getQueryParams();
          } else {
            this.router.navigateByUrl('products/invalid-category');
          }
        });
      } else {
        this.selectedCategoryName = COMMERCIAL_CATEGORY_TREE_ROOT.name;
        this.categoryHierarchyHasLessThanTwoLevels = true;
        this.pageTitle = this.selectedCategoryName;
        this.levelOneCategoriesNodes = tree.root.children;
        this.isCategoriesLoaded = true;
        this.getQueryParams();
      }
    }
  }

  getQueryParams(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe({
        next: (params: Params) => {
          this.isPreOrderableProducts = params.preorderablProducts;
          this.getSortingAndPaginationQueryParams(params);
          this.setupQueryParamsObject();
          this.sortingOptions = CATEGORY_PRODUCTS_SORTING_OPTIONS;
          this.onlyGroupsOnSale = params.onlyGroupsOnSale === 'true';
          this.onlyDiscountedAsSecondInPlacement =
            params?.onlyDiscountedAsSecondInPlacement === 'true';
          this.merchantLockedOnly = params?.merchantLockedOnly === 'true';

          if (params.featureGroupId) {
            this.currentUserAction = CategoryProductsUserActions.navigate_to_featured_group;
            this.getFeatureProductsQueryParams(params);
            this.getFeaturedProductsGroup();
          } else if (params.lockedTestableProducts) {
            this.getLockedTestableProducts();
          } else {
            if (!params.preorderablProducts) {
              if (params.q) {
                this.currentUserAction = CategoryProductsUserActions.product_search;
                if (this.searchOptimizationFeatureFlag) {
                  this.sortingOptions = [
                    SORT_BY_CLOSEST_MATCH_OPTION,
                    ...CATEGORY_PRODUCTS_SORTING_OPTIONS,
                  ];
                  this.detectSorting(params);
                }
              } else {
                this.currentUserAction = CategoryProductsUserActions.navigate_to_category;
              }
            }
            if (params.q) {
              const openForTestingQueryParams = {
                query: this.searchKey,
              };
              this.getLockedTestableProducts(openForTestingQueryParams, true);
            } else {
              this.getProductsForCategory();
            }
          }
          this.getMiniProducts();
        },
      }),
    );
  }

  getFeatureProductsQueryParams(params: any): void {
    this.pageTitle = params.featureGroupTitle;
    this.featureGroupId = params.featureGroupId;
    this.featureGroupTitle = params.featureGroupTitle;
  }

  getSortingAndPaginationQueryParams(params: any): void {
    this.searchKey = params.q || this.defaultKey;
    this.currentPage = +params.currentPage || this.defaultCurrentPage;
    this.maxItemPerPage = +params.items || 12;
    this.lockedOnly = params.lockedOnly === 'true';
    this.onlyGroupsOnSale = params.onlyGroupsOnSale === 'true';
    this.onlyDiscountedAsSecondInPlacement = params.onlyDiscountedAsSecondInPlacement === 'true';
    this.detectSorting(params);
  }

  getProductsForCategory(): void {
    this.state = 'loading';
    this.products = [];
    this.variantGroups = [];
    const filters: { [attribute: string]: any } = {
      pageSize: this.maxItemPerPage,
      page: this.currentPage,
      query: this.searchKey,
      sortBy: this.sortedBy.value,
      lockedOnly: this.lockedOnly,
      onlyGroupsOnSale: this.onlyGroupsOnSale,
      ...(this.shouldShowStockDistribution ? { merchantLockedOnly: this.merchantLockedOnly } : {}),
      ...(this.shouldShowDiscountedAsSecondProductFlag
        ? { onlyDiscountedAsSecondInPlacement: this.onlyDiscountedAsSecondInPlacement }
        : {}),
    };
    if (this.selectedCategoryId) {
      filters.commercialCategoryId = this.selectedCategoryId;
    }
    if (this.isPreOrderableProducts) {
      this.getPreOrderProductList();
    } else {
      this.productService
        .getProductsForCategory(filters)
        .pipe(
          finalize(() => {
            this.state = 'loaded';
          }),
        )
        .subscribe({
          next: (res: any) => {
            this.variantGroups = res.results;
            this.noOfItems = res.count || res.results.length;
            this.isProductsAvailable = this.noOfItems >= 1;
            if (filters.query) {
              this._logMixpanelEventUseCase.execute({
                eventName: 'product_search',
                payload: {
                  ...filters,
                  currentPage: location.href,
                  search_results_count: this.noOfItems,
                  'Category Name': this.selectedCategoryName,
                  'Is Products Available': this.isProductsAvailable,
                },
              });
            } else {
              this._logMixpanelEventUseCase.execute({
                eventName: 'Go_to_category',
                payload: {
                  ...filters,
                  currentPage: location.href,
                  results_count: this.noOfItems,
                  'Category Name': this.selectedCategoryName,
                  'Is Products Available': this.isProductsAvailable,
                },
              });
            }
            this.showPagination = this.noOfItems > this.maxItemPerPage;
            window.scrollTo(0, 0);
            if (
              this.currentUserAction === CategoryProductsUserActions.product_search &&
              this.variantGroups.length === 0 &&
              !this.isPreOrderableProducts &&
              this.shouldShowTestableProductsSearch &&
              this.testingProducts.length === 0
            ) {
              this.router.navigate(['products', 'no-search-results'], {
                queryParams: { searchKey: this.searchKey },
              });
            }
          },
        });
    }
  }

  getFeaturedProductsGroup(): void {
    this.variantGroups = [];
    this.products = [];
    this.state = 'loading';
    this.productService.getFeaturedProductsGroup(this.featureGroupId, country.code).subscribe({
      next: (res) => {
        this.products = res.data.products;
        this.noOfItems = this.products?.length;
        this.isProductsAvailable = this.noOfItems >= 1;
        this.selectedCategoryName = {
          arabicName: COLLECTION_PRODUCTS_CAROUSEL_NAME,
          englishName: COLLECTION_PRODUCTS_CAROUSEL_NAME,
        };
        window.scrollTo(0, 0);
        this.state = 'loaded';
      },
      complete: () => {
        this._logMixpanelEventUseCase.execute({
          eventName: GO_TO_CATEGORY,
          payload: {
            'Category Name': FEATURED_PRODUCTS,
          },
        });
      },
    });
  }

  getLockedTestableProducts(queryParams?: any, callSearch?: boolean): void {
    this.state = 'loading';
    this.variantGroups = [];
    this.products = [];
    const params: LockedTestableProductFilterModel = {
      page: 1,
      pageSize: this.maxItemPerPage,
      ...queryParams,
    };

    LockedTestableProductsUseCaseResolver.LockedTestableProductsList(params)
      .then((res) => {
        /* eslint-disable-next-line */
        callSearch ? (this.testingProducts = res.data) : (this.products = res.data);
        if (!callSearch) {
          this.noOfItems = res.count;
        }
        this.isProductsAvailable = this.noOfItems >= 1;
        this.showPagination = this.noOfItems > this.maxItemPerPage;
      })
      .finally(() => {
        this.state = 'loaded';
        if (callSearch) {
          this.getProductsForCategory();
        }
      });
  }

  resizeImages(): void {
    this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.RESIZE_IMAGES).subscribe({
      next: (flag) => {
        if (flag) {
          this._getFeatureAttributeUsecase
            .execute(FEATURE_FLAGS.ALLOWED_IDS_FOR_RESIZE_IMAGES)
            .subscribe({
              next: (ids) => {
                ids = JSON.parse(ids) || [];
                this.isResizeImageEnabled = ids?.length === 0 || ids.includes(user.id);
              },
            });
        }
      },
    });
  }

  getMiniProducts(): void {
    const bestSellersQuery = {
      pageSize: 3,
      page: 1,
      sortBy: SORT_BY_ORDER_COUNT,
      category: ALL_PRODUCTS_CATEGORY,
      countable: false,
    };
    this.miniProductsOne = [];
    this.resizeImages();
    this.productService.getProductsForCategory(bestSellersQuery).subscribe({
      next: (res: any) => {
        this.miniProductsOne = res.results.map((variantGroup: any) => ({
          ...variantGroup.primaryVariant,
          productPicture: this.isResizeImageEnabled
            ? getSizedImage(variantGroup.primaryVariant.productPicture, ImageSize.small)
            : variantGroup.primaryVariant.productPicture,
        }));
      },
    });

    const newProductsQuery = {
      pageSize: 3,
      page: 1,
      sortBy: SORT_BY_INTRODUCED_AT,
      category: ALL_PRODUCTS_CATEGORY,
      countable: false,
    };
    this.miniProductsTwo = [];
    this.productService.getProductsForCategory(newProductsQuery).subscribe({
      next: (res: any) => {
        this.miniProductsTwo = res.results.map((variantGroup: any) => ({
          ...variantGroup.primaryVariant,
          productPicture: this.isResizeImageEnabled
            ? getSizedImage(variantGroup.primaryVariant.productPicture, ImageSize.small)
            : variantGroup.primaryVariant.productPicture,
        }));
      },
    });
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.deepLinkSearch();
  }

  deepLinkSearch(): void {
    const queryString: { [attribute: string]: any } = {
      q: this.searchKey,
      currentPage: this.currentPage,
      items: this.maxItemPerPage,
      sorting: this.sortedBy.value,
      lockedOnly: this.lockedOnly,
      onlyGroupsOnSale: this.onlyGroupsOnSale,
      ...(this.shouldShowDiscountedAsSecondProductFlag
        ? { onlyDiscountedAsSecondInPlacement: this.onlyDiscountedAsSecondInPlacement }
        : {}),
    };
    if (this.isPreOrderableProducts) {
      queryString.preorderablProducts = true;
      this.getPreOrderProductListV2(queryString);
      this.scrollToClickedCategory();
    } else if (this.isLockedTestableProducts) {
      const queryParams = { page: this.currentPage, sortBy: this.sortedBy.value };
      this.getLockedTestableProducts(queryParams);
    } else {
      this.router.navigate(['products', 'category', this.selectedCategoryId], {
        queryParams: queryString,
      });
    }
  }

  changeItemsOnPage(num: number): void {
    this.maxItemPerPage = num;
    this.currentPage = 1;
    this.deepLinkSearch();
  }

  changeSorting(sortBy: string): void {
    this.sortingOptions.forEach((sortingOption) => {
      if (sortBy === sortingOption.value) {
        this.sortedBy = { ...sortingOption };
      }
    });
    this.deepLinkSearch();
  }

  changeOnSaleGroup(groupSale: any): void {
    this.onlyGroupsOnSale = groupSale.checked;
    this.deepLinkSearch();
  }

  changeOnDiscountedProducts(checkbox: any): void {
    this.onlyDiscountedAsSecondInPlacement = checkbox.checked;
    this.deepLinkSearch();
  }

  detectSorting(params: any): void {
    let defaultSorting = OLD_DEFAULT_SORTING_OPTION;
    if (this.introduceProductFeatureFlag) {
      defaultSorting = DEFAULT_SORTING_OPTION;
    }
    if (params.sorting) {
      const currentSortingOptionInUrl = this.sortingOptions.filter((sortingOption) => {
        return sortingOption.value === params.sorting;
      })[0];
      this.sortedBy = currentSortingOptionInUrl || defaultSorting;
    } else {
      this.sortedBy = defaultSorting;
    }
  }

  getCatalogedProducts(): void {
    this.catalogService
      .getCatalogedProducts()
      .pipe(
        finalize(() => {
          this.isCatalogDataLoaded = true;
        }),
      )
      .subscribe();
  }

  setupQueryParamsObject(): void {
    this.queryParamsObject = {
      items: this.maxItemPerPage,
      sorting: this.sortedBy.value,
      onlyGroupsOnSale: this.onlyGroupsOnSale,
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getPreOrderProductList(): void {
    const currentCountry = country.code;
    const userHasPreOrder = this._checkUserFeatureExistsUseCase.execute(
      PRE_ORDER_USER_FEATURE + currentCountry.toLowerCase(),
    );
    if (userHasPreOrder) {
      this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.PRE_ORDER_V2).subscribe({
        next: (preorderV2Flag) => {
          if (preorderV2Flag) {
            this.getPreOrderProductListV2();
          } else {
            this.getPreOrderProductListV1();
          }
        },
      });
    } else {
      this.isPreOrderableProducts = false;
      this.getProductsForCategory();
    }
  }

  getPreOrderProductListV1(): void {
    const query = {
      pageSize: this.maxItemPerPage,
      page: this.currentPage,
      sortBy: this.sortedBy.value,
    };
    combineLatest({
      bulkPreorderExpirementFeatureFlag: this._getFeatureFlagUseCase.execute(
        FEATURE_FLAGS.BULK_PRE_ORDERS_EXPERIMENT,
      ),
      userPreorderRequests: this._getBulkPreOrderRequestsUseCase.execute(),
      productsData: this._getPreorderableProducts.execute(query),
    }).subscribe({
      next: ({
        bulkPreorderExpirementFeatureFlag,
        userPreorderRequests,
        productsData: { products, count },
      }) => {
        if (bulkPreorderExpirementFeatureFlag) {
          this.variantGroups = products.map((variantGroup: any) => {
            const currentProductInProgressPreorderRequests = userPreorderRequests.filter(
              (request) =>
                request.prodId === variantGroup.primaryVariant.prodID &&
                request.status === PreOrderStatuses.INPROGRESS,
            );
            return {
              ...variantGroup,
              primaryVariant: {
                ...variantGroup.primaryVariant,
                isNotOrderable: currentProductInProgressPreorderRequests.length === 0,
              },
            };
          });
          this.noOfItems = count;
          this.isProductsAvailable = this.noOfItems >= 1;
          this.showPagination = this.noOfItems > this.maxItemPerPage;
          this.selectedCategoryName = {
            arabicName: BULK_PREORDERABLE_CAROUSEL_NAME_ARABIC,
            englishName: BULK_PREORDERABLE_CAROUSEL_NAME_ENGLISH,
          };
        }
      },
      complete: () => {
        this._logMixpanelEventUseCase.execute({
          eventName: VIEW_PREORDERABLE_PRODUCTS_PAGE,
        });
      },
    });
  }

  getPreOrderProductListV2(query?: any): void {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.BULK_PRE_ORDERS_EXPERIMENT).subscribe({
      next: (flag) => {
        if (flag) {
          query = {
            pageSize: this.maxItemPerPage,
            page: this.currentPage,
            sortBy: this.sortedBy.value,
            commercialCategoryId: this.selectedCategoryId,
          };

          this.state = 'loading';

          this._getPreorderableProducts.execute(query).subscribe({
            next: ({ products, count }) => {
              this.variantGroups = products;
              this.noOfItems = count;
              this.isProductsAvailable = this.noOfItems >= 1;
              this.showPagination = this.noOfItems > this.maxItemPerPage;
              if (!this.selectedCategoryId) {
                this.selectedCategoryName = {
                  arabicName: BULK_PREORDERABLE_CAROUSEL_NAME_ARABIC,
                  englishName: BULK_PREORDERABLE_CAROUSEL_NAME_ENGLISH,
                };
              }
            },
            complete: () => {
              this._logMixpanelEventUseCase.execute({ eventName: VIEW_PREORDERABLE_PRODUCTS_PAGE });
              this.state = 'loaded';
            },
          });
        } else {
          this.isPreOrderableProducts = false;
          this.getProductsForCategory();
        }
      },
    });
  }

  checkPreOrderUIRevamp(): void {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.PRE_ORDER_UI_REVAMP).subscribe({
      next: (flag) => {
        this.isPreOrderUIRevampEnabled = flag;
      },
    });
  }

  private _getIsEnglishLanguageSelected(): void {
    this.isEnglishLanguageSelected = this._translateService.currentLang === ENGLISH_LANGUAGE;
    this.subscriptions.push(
      this._translateService.onLangChange.subscribe({
        next: ({ lang }: { lang: string }) => {
          this.isEnglishLanguageSelected = lang === ENGLISH_LANGUAGE;
        },
      }),
    );
  }
}
